
/*
 * Des:
 * version:
 * Author: yuelanfenghua
 * Date: 2022-11-08 10:00:10
 * LastEditors: yuelanfenghua
 * LastEditTime: 2022-11-14 15:27:06
 */
import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon'// svg component
// 全局注册
Vue.component('svg-icon', SvgIcon)

// 引入所有svg文件
// const req = require.context('./file', false, /\.svg$/)

const requireAll = requireContext => requireContext.keys().map(requireContext)
// requireAll(req)