
/*
 * Des:
 * version:
 * Author: yuelanfenghua
 * Date: 2020-12-04 14:36:36
 * LastEditors: yuelanfenghua
 * LastEditTime: 2022-08-30 09:17:32
 */
import { getUrlParam } from '@/assets/utils/common'

const isBrowser = () => {
  return typeof window !== 'undefined'
}
const setCookie = (
  name,
  value,
  config = {
    path: '',
    days: 30,
    domain: '',
  },
) => {
  if (!isBrowser()) {
    return
  }

  const Days = config.days || 30
  const exp = new Date()
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${encodeURIComponent(
    value,
  )};Secure;SameSite=None;path=/;expires=${exp.toUTCString()};`

}

const getCookie = (name, path = '/') => {
  let cookie
  if (!isBrowser()) {
    // 这里需要在服务端处理好cookie，处理成{key:value}
    // global._cookies = global._cookies || {}
    // cookie = global._cookies[name] || null;
    return
  } else {
    const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`)
    const arr = document.cookie.match(reg)
    if (arr) {
      cookie = decodeURIComponent(arr[2])
    } else {
      cookie = window.localStorage.getItem(name) || cookie
    }
  }
  return cookie
}


function delCookie(name) {
  var exp = new Date()
  exp.setTime(exp.getTime() - 1)
  var cval = getCookie(name)

  if (cval != null) {
    document.cookie = `${name}=${cval};path=/;expires=${exp.toUTCString()}`
  }
}
/**
 * window.localstorsge
 * @param key
 * @param obj type must json
 */
function setStorageItem(key, obj) {
  try {
    let stringObj = JSON.stringify(obj || {})
    if (window.localStorage) {
      localStorage.setItem(key, stringObj)
    } else {
      setCookie(key, obj)
    }
  } catch (e) {
    console.error(key, obj, e)
    
    
  }
}
/**
 * window.localstorsge
 * @param key
 * @returns {null}
 */
const getStorageItem = (key) => {
  if (!key) {
    console.error('window.localStorage get key is not')
    return null
  }
  try {
    return JSON.parse(window.localStorage.getItem(key) || getCookie(key) || '')
  } catch (e) {
    return window.localStorage.getItem(key) || getCookie(key) || ''
  }
}
function delStorageItem(key) {
  if (!key) {
    console.error('window.localStorage get key is not')
    return null
  }

  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    delCookie(key)
  }
}


function clearHywToken() {
  let channelCode = getUrlParam('code')
  let token = getUrlParam('token')
  if (!channelCode) {
    window.channelCode = channelCode
    delStorageItem('channelCode')
  }
  if (!token) {
    window.myhywToken = token
    delStorageItem('hywToken')
  }
}
export {
  setCookie,
  getCookie,
  delCookie,
  isBrowser,
  setStorageItem,
  getStorageItem,
  delStorageItem,
  clearHywToken
}
