
import axios from 'axios'
import { getStorageItem, setStorageItem } from '@/cookie'
import { message } from 'element-ui'
import { Toast, Dialog } from 'vant';
import { goLogin } from '@/util/util'

axios.defaults.timeout = 5000;                        //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json';        //配置请求头
// axios.defaults.headers.post['channelCode'] = 'HYCF';        //配置请求头


axios.defaults.baseURL = '';   //配置接口地址
// axios.defaults.withCredentials = true
const instance = axios.create({
  baseURL: '',
  timeout: 30000,
  withCredentials: true,

})
//POST传参序列化(添加请求拦截器)
instance.interceptors.request.use((config) => {
  //在发送请求之前做某件事
  // let token = localStorage.getItem("token");
  // config.headers.common["token"] = token ? token : "";
  // config.headers.common["channelCode"] = "HYCF";
  // config.headers['channelCode'] = "HYCF";
  // config.headers['Cookie'] =config.headers['Cookie'] +';'+ window.localStorage.getItem('JSESSIONID')
  if (config.data && !config.data.appId) {
    config.data.appId = process.env.VUE_APP_APPID
  }
  const token = window.mytoken || getStorageItem('token') || ''
  if (token) {
    config.headers['token'] = token
  }
  const hywToken = getStorageItem('hywToken') || window.myhywToken || ''
  if (hywToken) {
    config.headers['hywToken'] = hywToken
  }
  const channelCode = window.channelCode || getStorageItem('channelCode')
  if (channelCode) {
    config.headers['channelCode'] = channelCode
  }
  // if (config.method.toLowerCase() == 'get') {
  /* if (config.url.indexOf('?') > -1) {
    config.url += '&v=' + new Date().getTime()
  } else {
    config.url += '?v=' + new Date().getTime()
  } */
  // }
  return config;
}, (error) => {
  console.log('错误的传参')
  return Promise.reject(error);
});

//返回状态判断(添加响应拦截器)
instance.interceptors.response.use((res) => {
  // AUTH_HYW_ERROR AUTH_TOKEN_INVALID
  // 代表 大会员体系

  if (res && res.data && (['AUTH_HYW_ERROR', 'AUTH_TOKEN_INVALID'].indexOf(res.data.code) > -1)) {
    goLogin();
    return res
  }

  if (res && res.data && res.data.data && res.data.data.token) {
    window.mytoken = res.data.data.token
    // document.cookie = `JSESSIONID=${res.data.data.sessionId};Secure;SameSite=None`
    setStorageItem('token', res.data.data.token)
  }
  if (res.data.errmsg) {
    errorDialog(res.data.message);
    /* message.error({
      message: res.data.message,
      duration: 3000,
    }) */
    // Toast.fail({
    //   message: res.data.errmsg,
    //   duration: 3000,
    // })
  } else if (!res.data.ok) {
    errorDialog(res.data.message);
    /* message.error({
      message: res.data.message,
      duration: 3000,
    }) */
    // Toast.fail({
    //   message: res.data.message,
    //   duration: 3000,
    // })
  }
  // console.log(res)

  return res;
}, (error) => {
  console.log('========', error)
  // errorDialog(JSON.stringify(error));
  errorDialog('网络异常，请稍后重试！');
  /* message.error({
    message: JSON.stringify(error),
    duration: 3000,
  }) */
  // Toast.fail({
  //   message: JSON.stringify(error),
  //   duration: 3000,
  // })
  // return Promise.reject(error);
  return Promise.resolve(error);
});

//返回一个Promise(发送post请求)
// query 数组 拼接请求地址 / 
/* 
url: '连接地址 path参数直接放里面',
    method: 'post 默认是 get',
    params: '必须是一个无格式对象 query参数',
    data: '是作为请求主体被发送的数据 body参数',
    timeout: 1000,
    headers: 'object 发送的自定义请求头'
*/

function errorDialog(msg) {
  const state = process.env.VUE_APP_MEMBER.indexOf(window.location.hostname) > -1;
  if (state) {
    Dialog.alert({
      // lockScroll: false,
      // closeOnClickOverlay: true,
      title: '温馨提示',
      width: '29.5rem',
      className: 'memberViewDialog',
      overlayClass: 'memberOverlayDialog',
      message: msg,
      confirmButtonText: '我知道了',
      confirmButtonColor: 'rgba(0, 0, 0, 0.85)',
    });
  } else {
    message.error({
      message: msg,
      duration: 3000,
    })
  }


}
export function fetchAll(url, config, query) {
  if (Array.isArray(query) && query.length > 0) {
    url += '/' + query.join('/')
  }
  config.url = url
  config.method = config.method || 'post'

  return new Promise((resolve, reject) => {
    instance(config)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err);
      })
      .catch((error) => {
        reject(error)
      })
  })
}

//返回一个Promise(发送post请求)
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    instance.post(url, params)
      .then(response => {
        resolve(response);
      }, err => {
        reject(err);
      })
      .catch((error) => {
        reject(error)
      })
  })
}
////返回一个Promise(发送get请求)
export function fetchGet(url, param, token) {
  if(url.indexOf('?')>-1) {
    url += '&v='+ Math.ceil(Math.random()*1*10000)
  } else {
    url += '?v='+ Math.ceil(Math.random()*1*10000)
  }

  return new Promise((resolve, reject) => {
    instance.get(url, { params: param })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
export default {
  fetchPost,
  fetchGet,
  fetchAll,
}
